<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-6">
                <h4 class="title-in-h">{{info_page.header}}</h4>
                <breadcrumb >
                    <breadcrumb-item  v-for="breadcrumb in breadcrumbs" v-if="breadcrumb.type!=='active'">
                        <router-link :to="{path:breadcrumb.path}"  class="color-blue-var">{{breadcrumb.name}}</router-link>
                    </breadcrumb-item>
                    <breadcrumb-item active v-else>
                        {{breadcrumb.name}}
                    </breadcrumb-item>
                </breadcrumb>
            </div>
            <div class="col-md-6 v-middle">
                <div class="box-description-c">
                    <label><span class="bg-circle bg-normal"></span> Tarifa contrato</label>
                    <label><span class="bg-circle bg-custom"></span> Tarifa manual</label>
                    <label><span class="bg-circle bg-rule"></span> Tarifa de regla</label>
                    <label><span class="bg-circle bg-ratetiger"></span> Tarifa de Ratetiger</label>
                </div>
            </div>


        </div>


        <div class="row">
            <div class="col-12">
                <card v-loading="screenLoading"
                      element-loading-lock="true"
                      :element-loading-text="screenText"
                      element-loading-customClass="min-height-large"
                      element-loading-spinner="el-icon-loading">
                    <div>

                        <div class="body-rates">
                            <div class="row elem-header">
                                <div class="col-3 d-flex justify-content-center justify-content-sm-between flex-wrap">
                                    <div class="datepicker-left">
                                        <template>
                                            <el-date-picker v-model="datePicker" type="date" placeholder="Elige una fecha"
                                                            :picker-options="pickerOptions1" @change="pickDate()" clearable="">
                                            </el-date-picker>
                                        </template>
                                    </div>
                                    <div class="box-controls">
                                        <i class="fa fa-chevron-left" :class="info_page.btn_back_status?'':'n-visible'"  @click="updDate('minus')" ></i>
                                        <i class="fa fa-chevron-right"  @click="updDate('add')"></i>
                                    </div>
                                </div>
                                <div class="col-9 pl-0">
                                    <div class="container-rates n-ln">
                                        <div class="box-right l-complete">
                                            <div class="box-item">
                                                <div class="box-colum" v-for="item in info_month">
                                                    <div class="box-head">
                                                        <span class="month" v-text="item.month"></span>
                                                        <span class="day" v-text="item.day"></span>
                                                        <span class="name" v-text="item.dayN"></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <!--<div class="col-6 d-flex justify-content-center justify-content-sm-between flex-wrap mt-2">
                                    <label class="legend-top"> {{$t('rate.dashboard.legend')}}.</label>
                                </div>-->
                            </div>

                            <div class="row container-base">
                                <div class="container-rates" style="" v-for="(room, idx_k) in info_page.rooms">
                                    <div class="box-control">
                                        <div class="col-12">
                                            <div class="row">
                                            <div class="col-3 box-left fl-left  itm-complete">

                                                <div class="box-item">
                                                    <div class="box-cell title title-full text-black">
                                                    <span class="fa  icon" :class="info_page.collapse_status[idx_k]?'fa-chevron-up':'fa-chevron-down'" @click="changeCollapse(idx_k)" ></span>
                                                    <span class="lbl"  @click="changeCollapse(idx_k)">{{room[0]._id.name}}</span>
                                                    </div>
                                                    </div>

                                                    </div>
                                                <div class="col-9 pl-0 box-header">


                                                    <div class="box-item">
                                                        <div class="box-colum" v-for="(item, idx_v) in room[0].closedDates">
                                                            <div class="box-cell bg-black" :class="item.value===0?'bg-open':''">
                                                            <span class="fa"
                                                                    :class="item.value===0?'fa-check':'fa-times'" @click="detectChanges('closedDates', item, room[0]._id)"></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                        </div>
                                        </div>
                                        

                                        
                                    </div>


                                    <div class="box-body" :class="info_page.collapse_status[idx_k]?'opened':'closed'">

                                        <div class="col-12">
                                            <div class="row">
                                                <div class="col-sm-3 box-left fl-left">
                                                    <div class="box-item">
                                                        <div class="box-cell">
                                                            Inventario
                                                        </div>
                                                        <div class="box-cell">
                                                            Reservaciones
                                                        </div>
                                                        <div class="box-cell">
                                                            Fecha límite reservación
                                                        </div>
                                                        <div class="box-cell">
                                                            Mínimo de noches
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-9 box-right fl-left pl-0">

                                                    <div class="box-item">
                                                        <div class="box-colum" v-for="(item, idx_v) in room[0].inventories">
                                                            <div class="box-cell" :class="item.value===0?'bg-empty':''">
                                                                <input type="text" @keypress="isNumberKey($event)"  v-model="item.value" @change="item.value = toFixed(item.value, 0); detectChanges('inventory', item, room[0]._id)">
                                                            </div>
                                                            <div class="box-cell disabled"  >
                                                                <input type="text" :value="room[0].reservations.totals[idx_v].total_reservas" disabled="disabled">
                                                            </div>

                                                            <div class="box-cell">
                                                                <input type="text" @keypress="isNumberKey($event)"  v-model="room[0].cutOffs[idx_v].value" @change="room[0].cutOffs[idx_v].value = toFixed(room[0].cutOffs[idx_v].value, 0); detectChanges('cutOff', room[0].cutOffs[idx_v], room[0]._id)">
                                                            </div>
                                                            <div class="box-cell">
                                                                <input type="text" @keypress="isNumberKey($event)" v-model="room[0].min_nights[idx_v].value" @change="room[0].min_nights[idx_v].value = toFixed(room[0].min_nights[idx_v].value, 0); detectChanges('min_nights', room[0].min_nights[idx_v], room[0]._id)">
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="box-rates " v-for="(item, idxRp) in room[0].rates_plan">
                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-sm-3 box-left fl-left">
                                                        <div class="box-item">
                                                            <div class="box-cell title" :class="item.rateplanlink_data.length?'':'ml-c'">
                                                                <span class="fa fa-link icon"  v-tooltip.bottom="getInfo(item, room[0].rates_plan)"></span>
                                                                <!--{{item.rateplanlink_data.length?+'':''}}-->
                                                                <span class="lbl">{{item.rateplan_data.name}}</span>

                                                            </div>
                                                            <div class="box-cell" v-for="(item_, idx) in info_page.head_rateplan">
                                                                {{item_}} <span v-if="idx===0">({{item.rateplan_data.currency}})</span><span class="ml-2" v-if="idx===0">{{(item.rateplan_data.meal_plan!==3 && item.base_pax!==undefined)?item.base_pax:'2'}}<i class="fa fa-user"></i></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-9 box-right fl-left pl-0">
                                                        <div class="box-item">
                                                            <div class="box-colum" v-for="(itm, idx) in item.rates">

                                                                <div class="box-cell bg-black bg-open" v-if="itm.value!==null">
                                                                    <span class="fa fa-check"></span>
                                                                </div>
                                                                <div class="box-cell bg-black head-disabled" v-if="itm.value===null">
                                                                    <span class="fa fa-lock" v-tooltip.bottom="'Día no aplicable en  la vigencia del contrato'"></span>
                                                                    <!--<span class="el-icon-lock"></span>-->
                                                                </div>
                                                                <div class="box-cell" :class="item.rateplanlink_data.length?'disabled':''" v-if="itm.value!==null">

                                                                    <input type="text" @keypress="isNumberKey($event)" :value="toFixed(itm.value, 2)" :disabled="item.rateplanlink_data.length?true:false" :class="checkRate(itm)" v-if="item.rateplanlink_data.length>0">
                                                                    <input type="text" :data-value="configureDecimals(2,[idx_k, idxRp,  idx],  ['rateConfig', itm, item.rateconfig_id])" v-model="itm.value"
                                                                           @keypress="isNumberKey($event)"
                                                                           @change="configureDecimals(2,[idx_k, idxRp,  idx],  ['rateConfig', itm, item.rateconfig_id], true)" :class="checkRate(itm)"

                                                                           :disabled="item.rateplanlink_data.length?true:false" v-else>
                                                                </div>
                                                                <div class="box-cell bg-disabled"  v-if="itm.value===null">
                                                                    <input type="text" value="" disabled="true">
                                                                </div>
                                                                <!-- <div class="box-cell mb-2 bg-black">
                                                                     <span class="fa"
                                                                           :class="rateConfig.status===1?'fa-check':'fa-times'"></span>
                                                                 </div>
                                                                 <div class="box-cell">
                                                                     <input type="text" v-model="rateConfig.min_night">
                                                                 </div>
                                                                 <div class="box-cell">
                                                                     <input type="text" v-model="rateConfig.max_night">
                                                                 </div>
                                                                 <div class="box-cell">
                                                                     <input type="text" v-model="rateConfig.arrive">
                                                                 </div>
                                                                 <div class="box-cell">
                                                                     <input type="text" v-model="rateConfig.departure">
                                                                 </div>-->
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>

                            </div>


                        </div>

                        <div class="text-right mt-5 mb-3">
                        </div>
                        <div class="clearfix"></div>
                    </div>
                </card>
            </div>
        </div>

        <div class="box-footer" :class="(info_page.rate_array.length)?'showme':''">
            <div class="box-inside text-center">
                <button v-if="writting_permission" class="btn btn-danger btn-wd btn-default ml-1 mr-1" @click="cancelForm">Cancelar</button>
                <button v-if="writting_permission" class="btn btn-success btn-wd btn-default ml-1 mr-1"  @click="sendForm()">Guardar</button>
                <p v-if="!writting_permission">
                    No tienes permisos de escritura sobre las tarifas
                </p>
            </div>
        </div>
    </div>

</template>
<script>

    import swal from 'sweetalert2'

    import {DatePicker, TimeSelect} from 'element-ui'
    import {Breadcrumb, BreadcrumbItem } from 'src/components/index'
    import RateService from '../../../js/services/RateService';
    import moment from 'moment';
    import ContractService from '../../../js/services/ContractService';
    import ReservationService from '../../../js/services/ReservationService';
    import RoomService from '../../../js/services/RoomService';
    import { vueTopprogress } from 'vue-top-progress'


    const rateService = new RateService();
    const contractService = new ContractService();
    const reservationService = new ReservationService();
    import EventBus from "../../../js/helpers/EventBus";
    const roomService = new RoomService();

    import { mapGetters } from "vuex";
    import Loading from 'src/pages/Dashboard/Layout/LoadingMainPanel.vue'


    export default {
        components: {
            Breadcrumb,
            BreadcrumbItem,
            [DatePicker.name]: DatePicker,
            [TimeSelect.name]: TimeSelect,
            vueTopprogress
        },
        computed: {
            ...mapGetters(["selected_property","writting_permission"]),
        },
        data() {
            return {
                screenLoading: false,
                screenText: '',
                breadcrumbs:[
                    {
                        name: this.$t('property.index.home'),
                        path: '/admin/properties',
                        type: '',
                    },

                    {
                        name: this.$t('rate.dashboard.header'),
                        path: '',
                        type: 'active',
                    }

                ],

                records:[],
                info_page: {
                    status: true,
                    header: this.$t('rate.dashboard.header'),
                    property_id: '',
                    start_date: null,
                    end_date: null,
                    //head_rateplan: ['Tarifa MXN', 'Min noches', 'Max noches', 'Cerrado llegada', 'Cerrada Salida'],
                    head_rateplan: ['Tarifa'],
                    rooms: [],
                    contracts: [],
                    rate_array: [],
                    collapse_status: [],
                    btn_back_status: false,
                    btn_control_status: true
                    //inactives: 0
                },
                months: [
                    '', 'Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'
                ],
                days: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
                head_table: [],
                days_total: 0,
                month_active: 1,
                info_month: [],
                year_active: 1,
                items: {},
                pickerOptions1: {
                    disabledDate: time => {
                        var d = new Date();
                        d.setDate(d.getDate()-1);
                        //console.log('day', d)
                        return time <= d.getTime()
                    },
                },
                datePicker: '',
                section: 'ROOMS_RATES',
            }
        },
        created() {
            this.chargerItems(this.selected_property._id);
            this.$store.dispatch('CHANGE_SECTION',this.section);
            moment.locale('es');
        },
        methods: {

            changeProperty(){
                EventBus.$on('CHANGE_PROPERTY', (property_id) => {
                    if(this.$route.name==='Rates'){
                        this.chargerItems(property_id);
                    }
                })
            },

            pickDate() {
                this.initLoading('Cargando...')
                let date_ = null;
                let date_end = null;
                if(this.datePicker===null){
                    date_ = moment();
                    date_end = moment().add(13, 'days');
                }else{
                    date_ = moment(this.datePicker);
                    date_end = moment(this.datePicker).locale('es');
                    date_end = date_end.add(13, 'days');
                }


                /*date_ = moment(this.datePicker);
                date_end = moment(this.datePicker).locale('es');
                date_end = date_end.add(14, 'days');*/

                //console.log('detecto cambio en datpicker', this.datePicker);



                let rooms = this.info_page.rooms;

                this.updateDashboard(rooms, this.formatDate(date_), this.formatDate(date_end));

                this.configure_month(date_, date_end._d)
                //console.log('cambio la fecha ', date_, moment(date_end._d))
                this.info_page.start_date =  date_;
                this.info_page.end_date = moment(date_end._d);

                this.checkDateNow();

            },

            updDate(type_act){
                //console.log(this.info_page.start_date, this.info_page.end_date);
                //let date_ = moment(this.formatDate(this.info_page.start_date));
                this.initLoading('Cargando...')
                if(this.info_page.btn_control_status){
                    let date_ = moment(this.info_page.start_date, 'YYYY-MM-DD');
                    let date_end = moment(this.info_page.end_date, 'YYYY-MM-DD');


                    if(type_act==='add'){

                        date_ = date_.add(14, 'days').locale('es');
                        date_end = date_end.add(14, 'days').locale('es');
                       //console.log('add')
                    }else{
                        date_ = date_.subtract(14, 'days').locale('es');
                        date_end = date_end.subtract(14, 'days').locale('es');

                        let aux = date_.format('YYYY-MM-DD')
                        let date_now = moment();
                        if(moment(date_now.format('YYYY-MM-DD')).isAfter(aux, 'day')){


                            date_= date_now
                            date_end = moment().add(14, 'days').locale('es');
                            this.checkDateNow()
                        }
                    }

                    let rooms = this.info_page.rooms;
                    this.updateDashboard(rooms, this.formatDate(date_), this.formatDate(date_end));
                    this.configure_month(moment(date_), moment(date_end)._d)
                    //console.log(moment(date_))
                    this.info_page.start_date =  date_;
                    this.info_page.end_date = date_end;
                    this.checkDateNow();
                }
            },
            configureDecimals(decimals, keys, params, statusChange = false){
                if(statusChange){
                    this.detectChanges(params[0], params[1], params[2])
                }else{

                    //let valueReturn = parseFloat(params[1].value).toFixed(decimals);
                    this.info_page.rooms[keys[0]][0].rates_plan[keys[1]].rates[keys[2]].value =  this.toFixed(params[1].value, 2);
                }
            },
            toFixed(num, fixed) {

                //num = num.replace(/\D/g,'');
                if(isNaN(parseFloat(num)) || !isFinite(num)){
                    num =  num.replace(/[^\d.-]/g, '')
                }
                var re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
                //console.log('re', re);
                return num.toString().match(re)[0];

                //Math.round(floatNumber*100)/100;
            },
            checkDateNow(){
                let date_min = moment(this.info_page.start_date, 'YYYY-MM-DD');
                let date_now = moment();
               //console.log(date_min, date_now)
                if(date_min<=date_now){
                    this.info_page.btn_back_status=false;
                }else{
                    this.info_page.btn_back_status=true;
                }
            },


            cancelForm(){
                this.chargerItems(this.selected_property._id);
                //this.info_page.status = true;
                this.info_page.rate_array = [];
                this.records = [];
                /*let tableData = this.tableData;
                this.records.forEach(item => {
                    let element = tableData.find(xquery => xquery._id === item._id)
                    element.status = !element.status;
                })
                this.records = [];*/
            },

            updateStatus(id, status){
                let records = this.records;
                status = !status;

                let idx = records.findIndex(record => record._id === id);
                if(idx>=0){
                    records.splice(idx, 1);
                }else{
                    records.push({
                        _id: id,
                        status: status
                    })
                }
            },

            saveChanges(){
               //console.log('items modificados', this.records)
                let records = this.records;

                /*records.forEach(async (item, idx)=>{
                    let status =item.status;
                    let id =item._id;
                    let indexToDelete = this.tableData.findIndex((tableRow) => tableRow._id === id)
                    if (indexToDelete >= 0) {
                        this.tableData.splice(indexToDelete, 1)
                    }

                    if(status===true){
                        indexToDelete = this.items_i.findIndex((tableRow) => tableRow._id === id);
                        if (indexToDelete >= 0) {
                            this.items_i.splice(indexToDelete, 1)
                        }
                        status = 'false';

                    }else{
                        indexToDelete = this.items.findIndex((tableRow) => tableRow._id === id);
                        if (indexToDelete >= 0) {
                            this.items.splice(indexToDelete, 1)
                        }
                        status = 'true';
                    }

                    let contract_resp = await contractService.updateStatus(id, status)
                    //console.log('contract response', contract.data.data.rateplan)
                    let contract = contract_resp.data.data.rateplan;

                    if(contract.status===true){
                        await this.items.push(contract)
                    }else{
                        await this.items_i.push(contract)
                    }
                    this.info_page.actives =  this.items.length;
                    this.info_page.inactives =  this.items_i.length;
                    //await this.records.splice(idx, 1);




                });*/
                this.records = [];

            },

            async chargerItems(property_id) {

                this.initLoading();
                let date_ = moment().locale('es').format('YYYY-MM-DD');
                let date_end = moment().add(14, 'days').locale('es').format('YYYY-MM-DD');
                let date_ini = date_ = moment().locale('es');
                this.info_page.start_date =  moment();
                this.info_page.end_date = moment().add(13, 'days');


                if(this.$route.name==='RatesDates'){
                    let date_back = this.$route.params.date;
                    date_ = moment(date_back).locale('es').format('YYYY-MM-DD');
                    date_end = moment(date_back).add(14, 'days').locale('es');
                    date_ini = date_ = moment(date_back).locale('es');

                   //console.log('update month', date_ini, date_end)
                    this.info_page.start_date =  date_ini;
                    this.info_page.end_date = date_end;
                }
               //console.log('update month', date_ini, date_end)

                this.days_total = date_ini.daysInMonth();
                this.month_active = date_ini.format('MM');
                this.year_active = date_ini.format('YYYY');

                this.configure_month(date_, date_end)
                await this.getDataAll(property_id);
            },



            async getInfoRoom(room_id,  rooms, info_required){
                //console.log('rooms', info_required);
                let rateConInfo = [];
                let start_date = info_required.start_date;
                let end_date = info_required.end_date;
                let property_id =info_required.property_id;


                if(rooms.length > 0){

                    this.info_page.collapse_status.push(false);
                    let rateconfigs = await rateService.getRates({
                        room: room_id,
                        from: start_date,
                        to: end_date,
                        rateplan_status: 1

                    });
                    rateconfigs = rateconfigs.data.data.rooms;

                    //console.log('item', item);
                    let dataComplete = await rateService.getDataModified(room_id, {
                        from: start_date,
                        to: end_date,

                    });


                    //console.log('item', dataComplete.data.data);

                    let closedDates = dataComplete.data.data.closed_dates;
                    let cutOffs =  dataComplete.data.data.cutoff;
                    let inventories =  dataComplete.data.data.inventories;
                    let reservations = await reservationService.getReservationsCount({
                        room_id: room_id,
                        from: start_date,
                        to: end_date,
                        property_id: property_id,
                    });
                    reservations =  reservations.data.data.reservations;

                    /*let inventories = await rateService.getInventory(room_id, {
                        from: start_date,
                        to: end_date,

                    });
                    inventories =  inventories.data.data.inventories;




                    let closedDates = await rateService.getClosedDates(room_id, {
                        from: start_date,
                        to: end_date,
                    });

                    closedDates = closedDates.data.data.closed_dates;


                    let cutOffs = await rateService.getCutOffs(room_id, {
                        from: start_date,
                        to: end_date,
                    });

                    cutOffs =  cutOffs.data.data.cutoff;*/


                    rateconfigs[0].inventories = await inventories.sort(this.compareValues('date'));
                    rateconfigs[0].reservations = await reservations;
                    rateconfigs[0].closedDates = await closedDates.sort(this.compareValues('date'));
                    rateconfigs[0].cutOffs = await cutOffs.sort(this.compareValues('date'));




                    rooms.splice(0, 1);
                    rateconfigs[0].rates_plan = await this.orderContracts(rateconfigs[0].rates_plan);
                   //console.log('rateconfigs', rateconfigs)
                    this.info_page.rooms.push(rateconfigs);

                    if(rooms.length>0){
                        let room_id = '';
                        if(info_required.type==='upd'){
                            room_id = rooms[0][0]._id.room_id;
                        }else{
                            room_id = rooms[0]._id;
                        }




                        this.getInfoRoom(room_id, rooms, info_required)
                    }else{
                        if(this.$refs.topProgress){
                            this.$refs.topProgress.done()
                        }
                    }



                }


                //console.log('rateconfigs', rateconfigs);
            },

            async getDataAll(property_id) {
                this.info_page.property_id = property_id;
                this.info_page.rooms = [];
                let rooms_arr = [];
                let contracts_arr = [];
                let end_date = this.formatDate(this.info_page.end_date);
                let start_date = this.formatDate(this.info_page.start_date)


                let info_data = {
                    property: property_id,
                    from: start_date,
                    to: end_date,
                    rateplan_status: 1
                };


                let getrates = await rateService.getRates(info_data)
                getrates = getrates.data.data.rooms
                //console.log('--> Debug step 4:', getrates)

                this.info_page.rooms = [];
                await this.infoRoomData(getrates, info_data);

                //console.log('--> Debug step 4:', this.info_page.rooms)
                let  response2 = await contractService.getContracts(true);
                let contracts = response2.data.data.rateplans;
                await contracts.forEach(item => {
                    contracts_arr.push({
                        _id: item._id,
                        name: item.name,
                    })
                });
                this.closeLoading();
            },

            async infoRoomData(rooms, info_required){
                //console.log('rooms', rooms);
                let rateConInfo = [];

                //console.log('room', room);

                let idxRoom = 0;

                while(rooms.length > idxRoom){
                    let room =rooms[idxRoom];
                    let room_id =room._id.room_id;
                    this.info_page.collapse_status.push(false);
                    /*let rateconfigs = await rateService.getRates({
                        room: room_id,
                        from: start_date,
                        to: end_date,
                        rateplan_status: 1

                    });
                    rateconfigs = rateconfigs.data.data.rooms;*/

                    //console.log('item', item);
                    let dataComplete = await rateService.getDataModified(room_id, {
                        from: info_required.from,
                        to: info_required.to,

                    });



                    let closedDates = dataComplete.data.data.closed_dates;
                    let cutOffs =  dataComplete.data.data.cutoff;
                    let inventories =  dataComplete.data.data.inventories;
                    let min_nights =  dataComplete.data.data.min_nights;
                    let reservations = await reservationService.getReservationsCount({
                        room_id: room_id,
                        from: info_required.from,
                        to: info_required.to,
                        property_id: info_required.property,
                    });
                    reservations =  reservations.data.data.reservations;




                    room.inventories = await inventories.sort(this.compareValues('date'));
                    room.reservations = await reservations;
                    room.closedDates = await closedDates.sort(this.compareValues('date'));
                    room.cutOffs = await cutOffs.sort(this.compareValues('date'));
                    room.min_nights = await min_nights.sort(this.compareValues('date'));



                    room.rates_plan = await this.orderContracts(room.rates_plan);

                    //console.log('item2', room);

                    //rooms.splice(0, 1);
                    this.info_page.rooms.push([room]);
                    idxRoom++;
                }

                this.closeLoading();
                this.info_page.btn_control_status = true;


                /*if(rooms.length > 0){
                    let room =rooms[0];
                    let room_id =room._id.room_id;
                    this.info_page.collapse_status.push(false);

                    let dataComplete = await rateService.getDataModified(room_id, {
                        from: info_required.from,
                        to: info_required.to,

                    });



                    let closedDates = dataComplete.data.data.closed_dates;
                    let cutOffs =  dataComplete.data.data.cutoff;
                    let inventories =  dataComplete.data.data.inventories;
                    let min_nights =  dataComplete.data.data.min_nights;
                    let reservations = await reservationService.getReservationsCount({
                        room_id: room_id,
                        from: info_required.from,
                        to: info_required.to,
                        property_id: info_required.property,
                    });
                    reservations =  reservations.data.data.reservations;




                    room.inventories = await inventories.sort(this.compareValues('date'));
                    room.reservations = await reservations;
                    room.closedDates = await closedDates.sort(this.compareValues('date'));
                    room.cutOffs = await cutOffs.sort(this.compareValues('date'));
                    room.min_nights = await min_nights.sort(this.compareValues('date'));



                    room.rates_plan = await this.orderContracts(room.rates_plan);

                    //console.log('item2', room);

                    rooms.splice(0, 1);
                    this.info_page.rooms.push([room]);

                    if(rooms.length>0){

                        await this.infoRoomData(rooms, info_required);


                    }else{

                        this.closeLoading();
                        this.info_page.btn_control_status = true;

                    }



                }else{
                    this.closeLoading();
                    this.info_page.btn_control_status = true;
                }*/


            },


            async updateDashboard(rooms, start_date, end_date){
                this.info_page.rooms = [];
                this.info_page.btn_control_status = false;

                /*let info_required = {
                    property_id: this.selected_property._id,
                    start_date: start_date,
                    end_date: end_date,
                    type:  'upd'
                };*/

                let info_data = {
                    property: this.selected_property._id,
                    from: start_date,
                    to: end_date,
                    rateplan_status: 1
                };

                let getrates = await rateService.getRates(info_data)
                getrates = getrates.data.data.rooms
                console.log('getrates', getrates);
                this.info_page.rooms = [];
                await this.infoRoomData(getrates, info_data);




                //await this.getInfoRoom(rooms[0][0]._id.room_id, rooms, info_required);

                /*await rooms.forEach(async item => {
                    let rateConInfo = [];

                    //console.log('rooms2', item);
                    let rateconfigs = await rateService.getRates({
                        room: item[0]._id.room_id,
                        from: start_date,
                        to: end_date,

                    })
                    rateconfigs = await rateconfigs.data.data.rooms;

                    //console.log('--> Debug step 3:', item)


                    let inventories = await rateService.getInventory(item[0]._id.room_id, {
                        from: start_date,
                        to: end_date,
                    });
                    inventories = await inventories.data.data.inventories;

                    let reservations = await reservationService.getReservationsCount({
                        room_id: item[0]._id.room_id,
                        from: start_date,
                        to: end_date,
                        property_id: this.selected_property._id,

                    });

                    reservations = await reservations.data.data.reservations;


                    let closedDates = await rateService.getClosedDates(item[0]._id.room_id, {
                        from: start_date,
                        to: end_date,
                    });

                    closedDates = await closedDates.data.data.closed_dates;


                    let cutOffs = await rateService.getCutOffs(item[0]._id.room_id, {
                        from: start_date,
                        to: end_date,
                    });

                    cutOffs = await cutOffs.data.data.cutoff;



                    rateconfigs[0].inventories = await inventories.sort(this.compareValues('date'));
                    rateconfigs[0].reservations = await reservations;
                    rateconfigs[0].closedDates = await closedDates.sort(this.compareValues('date'));
                    rateconfigs[0].cutOffs = await cutOffs.sort(this.compareValues('date'));



                    if(rateconfigs.length){
                        rateconfigs[0].rates_plan = await this.orderContracts(rateconfigs[0].rates_plan);
                        await this.info_page.rooms.push(rateconfigs);
                    }



                });*/
            },
            orderContracts(array_response){
                let array_return = [];
                let array_secondary= array_response.filter(item => item.rateplanlink_data.length > 0);

                let array_primary = array_response.filter(item => item.rateplanlink_data.length === 0)
                array_primary.forEach(item=>{

                    //console.log('--> Debug step 4.5:', item)
                    let array_aux = array_secondary.filter(elem=> elem.rateplanlink_data[0].rateplan ===  item.rateplan_data._id)
                    //console.log('--> Debug step 5:', array_aux)
                    array_return.push(item)
                    if(array_aux.length>0){
                        //console.log('--> Debug step 5.5:', array_aux)
                        array_return = array_return.concat(array_aux)
                    }

                })



                //console.log('--> Debug step 6:', array_return)

                return array_return;




            },
            formatDate(date) {
                console.log('debug - formatDate', date);
                return date.format('YYYY-MM-DD');
            },
            configure_month(date_start, date_end) {


                date_start = moment(date_start, 'YYYY-MM-DD')
                date_end = moment(date_end, 'YYYY-MM-DD')
                this.info_month = [];

                while (date_start <= date_end) {

                    this.info_month.push(
                        {
                            month: date_start.format('MMM').substr(0, 3),
                            day: date_start.format('DD'),
                            dayN: date_start.format('ddd').substr(0, 3)
                        }
                    )
                    date_start.add(1, 'days')
                }

                //console.log('fecha',this.info_month);


                /*console.log('fecha inicial:'+ date_start);
                console.log('fecha final:'+ date_finish);*/

            },

            getInfo(rateplan_info, ratePlans) {
                //console.log('info_object', rateplan_[0])
                let rateplan_ =  rateplan_info.rateplanlink_data;
                if(rateplan_.length>0){
                    let rateplan_id = rateplan_[0].rateplan

                    //console.log('info_object II ', rateplan_id, ratePlans)
                    let rateplan = ratePlans.find(item => item.rateplan_data._id === rateplan_id)
                    let linkRateplan = rateplan_info.rateplan_data.rateplan_link
                    //console.log('result', rateplan_info)
                    return 'Tarifa vinculada a: '+rateplan.rateplan_data.name+' '+linkRateplan.type+' '+linkRateplan.value;
                }
            },
            check_info(info_object) {
               //console.log('info_object', info_object)
            },

            detectChanges(type, varA, varB){


                switch (type) {
                    case 'rateConfig':
                        this.changeRate(varA, varB);
                        break;
                    case 'min_nights':
                    case 'inventory':
                    case 'cutOff':
                        this.changeValue(varA, varB, type);
                        break;
                    case 'closedDates':
                        varA.value = (varA.value===0)?1:0;
                        this.changeClosedDates(varA, varB);
                        break;

                }

            },

            changeClosedDates(item, room){

                let rate_array =  this.info_page.rate_array;
                let aux_date = moment(item.date, 'YYYY-MM-DD').format('YYYY-MM-DD');
               //console.log('linea 1:', aux_date, room.room_id, );
                let aux_var = rate_array.findIndex(elem => elem.date === aux_date && elem.room === room.room_id && elem.type === 'closedDates');

               //console.log('linea 2:', aux_var);
                let itm = {
                    date: aux_date,
                    room: room.room_id,
                    type: 'closedDates',
                    value: item.value
                }


                if(aux_var < 0){
                    rate_array.push(itm)
                }else{
                    rate_array[aux_var]= itm;
                    //rate_array.push(itm)
                }
               //console.log('linea 3:', rate_array);
            },
            isNumberKey(event)
            {
                var charCode = event.keyCode;
                if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                    event.preventDefault();;
                } else {
                    return true;
                }

                /*let val = event.target.value.trim()
                // It can only be positive integer or empty, and the rule can be modified according to the requirement.
                console.log(/^[1-9]\d*$|^$/.test(val), val, item_value);
                if (/^[1-9]\d*$|^$/.test(val)) {
                    item_value = val
                } else {
                    event.target.value = item_value
                }*/
            },

            changeValue(item, room, type_is){
               //console.log('linea 1:', item, room);

                let rate_array =  this.info_page.rate_array;
                let aux_date = moment.utc(item.date, 'YYYY-MM-DD').format('YYYY-MM-DD');
                let aux_var = rate_array.findIndex(elem => elem.date === aux_date && elem.room === room.room_id  && elem.type === type_is );

               //console.log('linea 2:', aux_var);
                let itm = {
                    date: aux_date,
                    room: room.room_id,
                    type: type_is,
                    value: item.value
                }


                if(aux_var < 0){
                    rate_array.push(itm)
                }else{
                    rate_array[aux_var]= itm;
                }
               //console.log('linea 3:', rate_array);
            },


            changeRate(itm, rateconfig_id){
                let actionForm = (itm._id!==undefined)?'edit':'add';


                //console.log('linea 1:', itm, rateconfig_id);
                itm.type= 'rateConfig';

                if(actionForm==='add'){
                    itm.rate_config = rateconfig_id;
                    itm.action = 'add';
                    //itm.date = this.formatDate(itm.date)
                    //console.log('itm', itm);
                    //rateService.createRate(itm);
                }else{
                    itm.action = 'edit';
                    //rateService.updateRate(itm);
                }

                //console.log('linea 2:', actionForm);

                let rate_array =  this.info_page.rate_array;
                let item = rate_array.findIndex(rate => rate.rate_config === itm.rate_config && rate.date === itm.date)

                //console.log('linea 3:', item);
                if(item < 0){
                    rate_array.push(itm)
                }else{
                    rate_array[item]= itm;
                }


                //console.log('linea 4:', rate_array);
                //
                //console.log('rate', item, this.info_page.rate_array);
            },
            async sendForm(){
                this.initLoading('Actualizando tarifas');
                let rate_array = this.info_page.rate_array;

                let ratesConfigs = rate_array.filter(elem => elem.type === 'rateConfig');
                ratesConfigs.map(elem => elem.type = 'custom');
                let inventories = rate_array.filter(elem => elem.type === 'inventory');
                let closedDates = rate_array.filter(elem => elem.type === 'closedDates');
                let cutOffs = rate_array.filter(elem => elem.type === 'cutOff');
                let minNights = rate_array.filter(elem => elem.type === 'min_nights');


                //console.log('info a enviar', rate_array.length,  inventories.length, inventories, cutOffs, cutOffs.length, closedDates, closedDates.length)
                let room_items = [];
                let room_inventories = [];
                let room_cutOffs = [];
                let room_minNights = [];

                if(closedDates.length || inventories.length || cutOffs.length || minNights.length){
                    let getUniquesItem = [];
                    //let getUniquesRoomn = [];
                    //let getUniquesRoomn = [];
                    closedDates.forEach(item=>{
                        let aux = getUniquesItem.indexOf(item.room);
                        //console.log('paso 2', getUniquesItem, item);
                        if(aux<0){
                            getUniquesItem.push(item.room)
                        }
                    })

                    inventories.forEach(item=>{
                        let aux = getUniquesItem.indexOf(item.room);
                        //console.log('paso 2', getUniquesRoomn, item);
                        if(aux<0){
                            getUniquesItem.push(item.room)
                        }
                    })
                    cutOffs.forEach(item=>{
                        let aux = getUniquesItem.indexOf(item.room);
                        //console.log('paso 2', getUniquesRoomn, item);
                        if(aux<0){
                            getUniquesItem.push(item.room)
                        }
                    })
                    minNights.forEach(item=>{
                        let aux = getUniquesItem.indexOf(item.room);
                        //console.log('paso 2', getUniquesRoomn, item);
                        if(aux<0){
                            getUniquesItem.push(item.room)
                        }
                    })


                   //console.log('paso 3', getUniquesItem );
                    getUniquesItem.forEach(uniqueRoom=>{
                        room_items = closedDates.filter(elem => elem.room === uniqueRoom);
                        room_items = room_items.sort(this.compareValues('date'));

                        room_inventories = inventories.filter(elem => elem.room === uniqueRoom);
                        room_inventories = room_inventories.sort(this.compareValues('date'));

                        room_cutOffs = cutOffs.filter(elem => elem.room === uniqueRoom);
                        room_cutOffs = room_cutOffs.sort(this.compareValues('date'));

                        room_minNights = minNights.filter(elem => elem.room === uniqueRoom);
                        room_minNights = room_minNights.sort(this.compareValues('date'));



                        //console.log('paso 4', room_items );
                        //rateService.saveClosedRates(uniqueItem, room_items);
                        let data_send = {
                            closed_dates: room_items,
                            cutoff: room_cutOffs,
                            inventory: room_inventories,
                            min_nights: room_minNights
                        }
                       //console.log('paso 4', data_send );
                        rateService.saveModified(uniqueRoom, data_send);
                    })
                }

                await rateService.arrayRates(ratesConfigs);
                /*ratesConfigs.forEach(async rate=>{
                    if(rate.action === 'add'){
                        //await rateService.createRate(rate);
                    }else{
                        //await rateService.updateRate(rate);
                    }
                })*/

                this.closeLoading();
                this.showSwal(this.$t('rate.dashboard.msg'));
            },

            compareValues(key, order = 'asc') {
                return function innerSort(a, b) {
                    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
                        // property doesn't exist on either object
                        return 0;
                    }

                    const varA = (typeof a[key] === 'string')
                        ? a[key].toUpperCase() : a[key];
                    const varB = (typeof b[key] === 'string')
                        ? b[key].toUpperCase() : b[key];

                    let comparison = 0;
                    if (varA > varB) {
                        comparison = 1;
                    } else if (varA < varB) {
                        comparison = -1;
                    }
                    return (
                        (order === 'desc') ? (comparison * -1) : comparison
                    );
                }
            },



            showSwal (msg) {

                swal({
                    title: this.$t('rate.dashboard.modal'),
                    text: msg,
                    buttonsStyling: false,
                    confirmButtonClass: 'btn btn-success btn-fill',
                    type: 'success'
                }).then(()=> {
                    this.info_page.rate_array = [];
                    console.log('info dates', this.info_page.start_date, this.info_page.end_date);
                    let date_ = moment(this.info_page.start_date, 'yyyy-mm-dd');
                    let date_end =  moment(this.info_page.end_date, 'yyyy-mm-dd');
                    let rooms = this.info_page.rooms;
                    this.updateDashboard(rooms, this.formatDate(date_), this.formatDate(date_end));

                });
            },
            changeCollapse(idx_k){
               //console.log('collapse', idx_k)
                /*this.info_page.collapse_status.forEach((item, idx) => {
                    if(idx_k!==idx){
                        this.$set(this.info_page.collapse_status, idx, false);
                    }
                })*/
                this.$set(this.info_page.collapse_status, idx_k, !this.info_page.collapse_status[idx_k]);
            },
            prinConsole(text, item){
               //console.log(text, item)
            },
            initLoading(text = 'Cargando...') {
                this.screenLoading = true;
                this.screenText = text;
            },
            closeLoading(){
                this.screenLoading = false;
            },
            checkRate(item){
                let classRate = '';
                if(item._id){
                    classRate = 'custom_rate'
                    if(item.type==='rule_1'){
                        classRate = 'rule_rate'
                    }
                    if(item.type==='ratetiger'){
                        classRate = 'ratetiger_rate'
                    }
                }
                return classRate;
            },
        },
        mounted () {
            this.changeProperty();
        },
    }
</script>
<style lang="scss">
    .container-rates{
        overflow: hidden;
        line-height: 0;
        width: 100%;
        &.n-ln{
            line-height: 1;
        }
    }
    .custom_rate{
        background-color: #6b3e914d !important;
    }
    .rule_rate{
        background-color: #A6DBFF !important;
    }
    .ratetiger_rate{
        background-color: #ffc766 !important;
    }
    .box-body{
        display: inline-block;
        overflow: hidden;
        &.opened{
            height: auto;
            transition: height 1s ease-in;
            //transform: translateY(0);
        }
        &.closed{
            height: 0;
            transition: height 1s ease-out;
            //display: none;
            //transform: translateY(-100%);
        }
    }
    .box-control{
        display: inline-block;
        position: relative;
        z-index: 2;
        background: #fff;
        width: 100%;
    }
    .box-controls{
        line-height: 30px;
        i{
            width: 30px;
            cursor: pointer;
            &.n-visible{
                visibility: hidden;
            }
        }
    }

    .box-right {
        width: calc(100% - 200px);
        display: inline-block;
        &.marl-200{
            margin-left: 200px;
        }
        &.l-complete{
            width: 100%;
        }
    }

    .box-item {
        position: relative;
        width: 100%;
        display: inline-block;
        white-space: nowrap;
        .box-colum {
            width: calc(100% / 14);
            display: inline-block;
            .box-head {
                width: 100%;
                display: inline-block;
                text-align: center;
                padding: 1px 0;
                border-bottom: 2px solid #bebebe;
                border-top: 2px solid #bebebe;
                color: #a4a4a4;
                span {
                    width: 100%;
                    display: inline-block;
                    font-size: 12px;
                    margin: 0;
                    text-transform: capitalize;
                    float: left;
                    line-height: 17px;
                    color: #343a40;
                    &.day{
                        font-size: 15px;
                        font-weight: 600;
                        margin: 0;
                        color: #272727;
                    }
                }
            }
            .box-cell {
                width: 100%;
                display: inline-block;
                //border: 1px solid #05539480;
                text-align: center;
                float: left;
                min-height: 20px;
                line-height: 20px;
                &.bg-black {
                    background-color: darkred;
                    color: #fff;
                    cursor: pointer;
                    min-height: 20px;
                    line-height: 20px;
                    border-bottom: 0;
                }
                &.bg-open{
                    background-color: #00AB69;
                }

                input {
                    width: 100%;
                    height: 20px;
                    border: none;
                    text-align: center;
                    font-size: 13px;
                    background: transparent;
                }
                input:disabled{
                    color: #929292;
                }
                span{
                    width: 100%;
                    height: 20px;
                    line-height: 20px;
                }
            }
        }
    }

    .fl-left {
        float: left;
    }
    .card{

        .legend-top{
            text-transform: none;
            color: #1a1a1a;
        }
    }

    .box-left {
        width: 200px;
        display: inline-block;
        &.itm-complete{
            width: 100%;
        }
        .box-item {
            float: left;
            overflow: visible;

        }
        .box-cell {
            padding-left: 20px;
            line-height: 21px;
            span{
                font-size: 14px;
            }
            &.title {
                padding-left: 30px;
                white-space: normal;
                font-weight: 600;
                color: #085394;
                line-height: 1;
                height: 21px;
                width: 100%;
                display: table;
                vertical-align: middle;
                .icon{
                    display: table-cell;
                    width: 30px;
                    vertical-align: middle;
                    color: #085394;
                }
                .lbl{
                    display: table-cell;
                    width: calc(100%);
                    vertical-align: middle;
                    padding-left: 5px;
                    font-size: 14px;
                    &.ml-c{
                        padding-left: 20px;
                    }
                }
            }
            &.ml-c{
                padding-left: 0;
                .icon{
                    display: none;
                }
                .lbl{
                    padding-left: 20px;
                }
            }
            &.title-full{
                padding-left: 0;
            }
        }
    }
    .text-black{
        color: #000000 !important;
        .icon{
            color: #000000 !important;
        }
    }
    .body-rates{
        .elem-header{
            position: sticky;
            top: 50px;
            z-index: 9;
            padding-top: 5px;
            background: #fff;

        }
    }
    .box-footer.showme{
        z-index: 2;
    }

    .body-rates {
        .box-control{
            .box-right{
                line-height: 1;
                height: 20px;
                overflow: hidden;
            }
        }
        .box-body{
            position: relative;
            .box-right{
                line-height: 1;
                .box-item{
                    line-height: 1.5;
                    margin-bottom: 5px;
                    .box-colum:first-child{
                        border-left: 1px solid #05539463;
                    }

                    .box-colum:last-child{
                        position: relative;
                        left: 0;
                        width: calc(100% / 14);
                    }
                    .box-colum{

                        border-right: 1px solid #05539463;

                        .box-cell:first-child{
                            border-top: 0;
                        }
                        .box-cell{
                            border-bottom: 1px solid #05539463;
                        }
                    }
                }

            }
        }
    }
    .box-header{
        .box-item{

            height: 100%;
            display: flex;
            .box-cell{

                display: flex;
                height: 100%;
                align-content: center;
                align-items: center;
            }
        }
        
    }
    .box-description-c{
        border: 1px solid #bebebe;
        padding: 5px 15px;
        display: inline-flex;
        align-items: center;
        margin-left: auto;
        .bg-custom{
            background-color: #6b3e914d !important;
        }
        .bg-normal{
            background-color: #fff !important;
        }
        .bg-rule{
            background-color: #A6DBFF !important;
        }
        .bg-ratetiger{
            background-color:#ffc766 !important;
        }
        .bg-circle{
            width: 15px;
            height: 15px;
            border-radius: 15px;
            border: 1px solid;
            display: inline-block;
            margin-right: 5px;
        }
        label{
            margin:0 10px;
            font-size: 14px;
            display: flex;
            align-items: center;
            line-height: 1;
        }
    }
    .v-middle{
        display: flex;
        align-items: center;

    }


    .datepicker-left{
        width: 70%;
        .el-date-editor.el-input
        {
            .el-input__inner {
                border: 2px solid #bebebe;
                height: 30px;
                line-height: 30px;
                padding-right: 5px;
                text-align: center;
            }
            .el-input__prefix, .el-input__suffix{
                height: 30px;
                .el-input__icon{
                    line-height: 30px;
                }
            }
        }
    }
    .card .card-body {
        padding: 5px 15px 10px 15px;
    }

</style>
